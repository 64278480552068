import loadable from '@loadable/component';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import { SectionDescription } from '../components/section-description';
import { SectionHeading } from '../components/section-heading';

const sectionStyle = {
  padding: '0 0 10vh'
};

const animationStyle = {
  maxWidth: '720px',
  margin: '0 auto'
};

const LottieAnimation = loadable(() => import('../components/animation'));

const SoonPage = () => {
  const [comingSoon, setComingSoon] = useState<{} | null>(null);

  useEffect(() => {
    fetch('/animations/coming-soon.json').then(async (r) => {
      const json = await r.json();
      console.log(json);
      setComingSoon(json);
    });
  }, []);

  return (
    <Layout pageTitle={'Coming Soon'}>
      <section className={'d-flex flex-column'} style={sectionStyle}>
        <div className="container text-center">
          {comingSoon && (
            <LottieAnimation
              animation={comingSoon!}
              threshold={0.42}
              loop={true}
              style={animationStyle}
            />
          )}

          <SectionHeading>We're still adding a few finishing touches</SectionHeading>
          <SectionDescription>
            Looks like you're a bit early. We aren't done tidying up this section of the website
            yet. You can <Link to={'/'}>go back home</Link> and explore something else.
          </SectionDescription>
        </div>
      </section>
    </Layout>
  );
};

export default SoonPage;
